<template>
  <Modal :modal-active="showModal">
    <template v-slot:default="{}">
      <div class="full-screen-container">
        <div class="text-center bg-modal">
          <div class="row col-12 Text container ">
                  <span class="container-fluid mt-5  text-modal-private">
                     <i class="fas fa-times icon-close" @click="closeModal"></i>
                  </span>               
                </div>
          <div class="auth-log">
            <div class="col-12 col-md-6 text-center  order-md-2" style="margin-top: -3px;">
              <img
                src="@/assets/icons/safe.svg"
                class="icon-safe mt-2"
                alt="Ícone de segurança"
              />
            </div>
            <div class="col-12 col-md-6 text-center text1 mb-3 mb-md-0">
              <p class="mb-3">
                Autenticação de <br />
                segurança
              </p>
            </div>
          </div>
          <div class="mt-2 text-center text2">
            Vamos fazer uma rápida verificação para evitar fraudes e te deixar
            mais tranquilo em sua compra?
            <br />
            <br />
            <p class="heading-title _align-center">
              Para prosseguir com o fluxo de venda clique no link abaixo ou
              scaneie o QRCode
            </p>
            <a
              @click="startVerification"
              class="block"
              target="_blank"
              style="color: white">
              {{ getLinkComputed }}
            </a>
            <div class="auth-log">
              <VueQrcode
                :value="getLinkComputed"
                :options="qrCodeOptions"
                tag="img"
                class="img mt-4"
              />
            </div>
          </div>
          <div class="mt-5 text3 col-12 row justify-content-center mx-1"     style="margin-bottom: -2px;">
            <button
              type="button"
              class="btn mb-5 button-verify col-5 mx-1"
              @click="copyLinkToClipboard">
              Copiar link
            </button>
            <button
              type="button"
              class="btn mb-5 button-verify col-5 mx-1"
              @click="confirm">
              Confirmar
            </button>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>  
<script>
import { mapActions, mapGetters } from "vuex";
import Modal from "@/components/Modal/ModalAutentica.vue";
import VueQrcode from "@chenfengyuan/vue-qrcode";
export default {
  name: "VerifyModal",
  components: {
    Modal,
    VueQrcode
  },
  data() {
    return {
      showModal: true,
      verificationDone: false,
      qrCodeOptions: {},
    }
  },
  computed: {
    ...mapGetters({
      getLinkComputed: "saleFlow/operators/claro/authenticate/getLink",
      confirmCallback:
        "saleFlow/operators/claro/authenticate/getConfirmCallback"
    })
  },
  methods: {
    ...mapActions({
      closeModal: "saleFlow/operators/claro/authenticate/closeModal",
      copyLinkToTransferAreaAction:"saleFlow/operators/claro/authenticate/copyLinkToTransferArea"
    }),
    closeModal() {
      this.showModal = false;
    },
    async copyLinkToClipboard() {
      try {
        await this.copyLinkToTransferAreaAction();
        this.$swal("Link copiado com sucesso.");
      } catch (error) {
        console.error("Erro ao copiar link:", error);
        this.$swal("Erro ao copiar Link");
      }
    },
    startVerification() {
      window.open(this.getLinkComputed, "_blank");
      this.verificationDone = true;
    },
    link() {
      this.getLink();
    },
    async confirm() {
      this.closeModal();
    },
  },
};
</script>  
<style lang="scss" scoped>
.text-modal-private {
  font-size: 18px;
  font-weight: bold;
  color: #1d3c85;
}
.icon-safe {
  width: 60px;
}
.text1 {
  color: #ffff;
  font-size: 30px;
}
.text2 {
  color: #ffff;
  font-size: 15px;
  margin-left: 12px;
  margin-right: 12px;

}
.full-screen-container{
 margin-left: -13px;
}
.bg-modal {
background-color: #1d3c85;
   
    border-radius: 19px;
    margin-right: -26px;
    margin-bottom: -5px;
    margin-top: -5px;
}
.button-verify {
  color: #1d3c85;
  background-color: #ffff;
  font-size: small;
  border-radius: 25px;
}
.loading-button i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: spinner 1s infinite linear;
}
@keyframes spinner {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.img {
  width: 131px;
}
.auth-log {
  text-align: -webkit-center;
}
.icon-close{
  float: right;
  margin-right: -22px;
  cursor: pointer;
  color: #ffff
}
.icon-close:hover {
  color: #7a7a7a;
}
</style>