    <template>
  <div v-if="isShowingRequest" style="background-color: #FFFFFF;">
    <div class="offcanvas offcanvas-end show " tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel"
          style=" margin-top: 23%; ">
       <div class="offcanvas-header shadow col-12" style="background-color: #FFFFFF;color:#1D3C85">
            <a class=" col-1 order-first" data-bs-dismiss="offcanvas" aria-label="Close" @click="closeRequest">
              <Icon name="chevron-left" />
            </a>
            <span class="col ms-3 " style="font-size: 22px;"> Meus pedidos</span>
            <span id="offcanvasRightLabel" class=" col-2 order-last"> </span>
        </div>
      <div class="offcanvas-body  container">
        <div class="container-fluid mt-3" style=" background-color: #FFFFFF;">
          </div>
            <div>
              <div class="container row mt-5 ">
                <div v-for="item in getOrderItems" :key="item" class="card"
                  style="width: 18rem; background-color: #F3F3F3;">
                  <ul class="list-group list-group-flush" style=" background-color: #F3F3F3;">
                    <li class="list-group-item" style=" background-color: #F3F3F3;"> {{item.items.id}}</li>
                    <li class="list-group-item" style=" background-color: #F3F3F3;"> {{item.items.name}}</li>
                  </ul>
                </div>
                  <h6 class="col-7 text-mcafee " style="color:blue">
              </h6>
              </div>
            <div class="mt-3">
          </div>
        </div>
        <hr>
      </div>
    </div>
   <div class="" />
  </div>
    </template>
    <script>
    import { mapActions, mapGetters, mapState } from "vuex"
    import Icon from "@/components/Icon/Icon"
    import { myOrders } from "@/modules/customer/services/orders.service.js"

    export default {
      name: "MyOrders",
      components: {
        Icon
      },
      data() {
        return {
          showRequests: false,
        }
      },
      mounted() {
      },
      computed: {
        ...mapGetters({
          getCartDetailsComputed: 'cart/getCartDetails',
          hasAvailableProductTypeClaroComputed: 'saleFlow/product/hasAvailableProductTypeClaro',
          getProductResidentialPointAdditionalTypes: 'saleFlow/product/getProductResidentialPointAdditionalTypes',
          getOrdersComputed: 'saleFlow/getOrders'
        }),
        // Not recommended use mapState, but getter not updated in this case. It's an exception
        ...mapState({
          isShowingRequest: (state) => state.cart.isShowingRequest
        }),
        getOrderItems() {
          return this.getOrdersComputed?.items || []
        },
      },
      methods: {
        ...mapActions({
          closeRequestAction: 'cart/closeRequest',
          removeCartItemAction: 'cart/removeProductCart',
        }),
        closeRequest() {
          this.closeRequestAction()
        },
     
      }
    }
    </script>
    <style scoped>
    .edit-button {
      background: rgb(214, 210, 210);
      margin-left: 56%;
      margin-top: -27%;
      font-size: 12px;
    }
    .text-mcafee {
      font-size: 13px;
      font-weight: bold;
    }
    .price-mcafee {
      margin-left: 5%;
      margin-top: 1%;
      font-size: 14px;
      font-weight: bold;

    }
    .total-price {
      margin-left: 40%;
      margin-top: 2%;
      font-size: 18px;
    }
    </style>
