const TRADE_APP_ONE_ROUTE = '/'
const  HP_MCAFEE_ROUTE = '/hpmcafee'
const  RIG_STAR_ROUTE = '/rigstar'
const  CLARO_TV_ROUTE = '/clarotv'


export default {
    TRADE_APP_ONE_ROUTE,
    HP_MCAFEE_ROUTE,
    RIG_STAR_ROUTE,
    CLARO_TV_ROUTE
}