<template>
  <Modal
    v-if="showCookieModal"
    :close="closeModal"
    :modal-active="showModal"
    style="margin-bottom: -20%;">   
    <template v-slot:default="{ close }">
      <div class="container-fluid py-3">
        <div class="row">
          <span class="container-fluid mt-1 text-modal-private">
            Controle sua privacidade
          </span>
        </div>
        <div class=" mt-3" style="color:#141414">
          Nosso site utiliza cookies para garantir a<br>
          melhor experiência com o nosso conteúdo.<br>
          Ao aceitar, entendemos que você concorda com a
          nossa política de privacidade.
        </div>
        <div class="container-fluid mt-5">
          <div class="d-flex justify-content-around">
           <Button
              type="button"
              class="btn border rounded text-button"
              tag="Política de privacidade"
              style="color: #1D3C85; background-color: #FFFF;"
              @click="openPolicyDocument">
              Política de privacidade              
            </Button>
            <Button type="button"
              class="btn border rounded text-button"
              tag="Aceitar Cookie"
              style="color: #FFFF; background-color: #1D3C85;"
              @click="close(partnerName)"
              :disabled="processing">
        <span v-if="!processing" class="text-sm">Aceitar</span>
        <span v-else>
          <div class="text-center">
            <strong>Processando...</strong>
            <div
              class="spinner-border ml-auto loading"
              role="status"
            >
            </div>
          </div>
        </span>
          </Button> 
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>
<script>
import {mapActions, mapGetters} from "vuex"
import Modal from "@/components/Modal/Modal"
import Button from "@/components/Form/Button/Button.vue"
import { sendCookieAcceptance } from "@/services/policy/cookie.service";
import { getNavigation, getDeviceByIp, getAndDisplayLocationInfo, locationError } from "@/services/DeviceInformation/DeviceInformation.service";
export default {
  name: "CookiesPolicy",
  components: {
    Modal,
    Button
  },
  data () {
    return {
      processing: false,
      showModal: true,
      pdfPath: 'TRADEAPPONE_POLITICA_DE_COOKIE_E_PRIVACIDADE.pdf'
    }
  },
  computed: {
    ...mapGetters({
      getCookiesPolicyAccepted: 'getCookiesPolicyAccepted',
    }),
    showCookieModal () {
      return this.getCookiesPolicyAccepted !== true
    },
    pdfUrl() {
      return require(`@/assets/privacyPolicy/${this.pdfPath}`);
    },
  },
  methods: {
    ...mapActions({
      acceptCookiePolicyAction: 'acceptCookiePolicy',
    }),
    async closeModal(partner) {
      const ip = await getDeviceByIp();
      try {
        this.disableButton();
        this.acceptCookiePolicyAction();
        const position = await getNavigation();
        const { latitude, longitude, locationName } = position?.coords || {};
        await sendCookieAcceptance(partner, latitude, longitude, locationName, ip);
      } catch (error) {
        if (error.code === 1) {
          await sendCookieAcceptance(partner, "", "", "", ip);
        } else {
          console.error("Geolocation error:", error);
        }
      } finally {
        this.showModal = false;
      }
    },
    openPolicyDocument () {
      window.open(this.pdfUrl, '_blank')
    },
    disableButton() {
      this.processing = true;
    }
  }      
}
</script>
<style scoped>
.text-modal-private{
  font-size: 18px;
  font-weight: bold;
  color: #1D3C85;
}
.text-button{
  font-size: 1rem;
  color: #fff;
  font-weight: 700;
}
.loading{
  width: 16px; 
  height: 16px;
}
.loading-button i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: spinner 1s infinite linear;
}
@keyframes spinner {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>

