<template>
  <div v-if="isShowingCart">
    <div class="offcanvas offcanvas-end show" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style="background: #F3F3F3;">
      <div class="offcanvas-header shadow" style="background-color: gray;color:#FFFFFF">
        <button type="button" class="btn-close col-1 order-first" data-bs-dismiss="offcanvas" aria-label="Close" @click="closeCart"></button>
        <span class="col ms-3"> Meu carrinho </span>
        <span id="offcanvasRightLabel" v-if="getShowClaroFieldsComputed" class=" col-4 order-last"> {{ currencyBRFormat(getCartTotalPricePoints) }}/Mês </span>  
        <span id="offcanvasRightLabel" v-if="hasAvailableProductTypeMcafeeComputed" class=" col-4 order-last"> {{ currencyBRFormat(getCartTotalPrice) }}/ano </span>  
      </div>
      <div class="offcanvas-body text-point container">
        <div class="container-fluid mt-3">Continue selecionando serviços ou <br>
          conclua seu pedido.
        </div>
        <div class="col-12 row">
          <div class="col" style="margin-top: 55px" v-if="isCartEmpty && getShowClaroFieldsComputed">
           <img src="@/assets/img/LogoClaroRedondo.png" alt="" style="width: 40px;">
          </div>
          <div class="col" :style="{ 'margin-left': getButtonMarginLeft }">
            <div v-for="cartItem in getCartItems" :key="cartItem.item.product?.id">
              <div class="row mt-5">
                <div class="container">
                  <h6 class="col-7 text-mcafee" style="color:blue">{{ cartItem.item.product.name }}</h6>
                </div>
                <div v-if="getShowClaroFieldsComputed" style="margin-bottom: -18px; margin-top: -21%;">
                  <Button tag="Remover" @click="removeCart(cartItem.item.product, 'claro')" type="button" class="rounded edit-button-claro col py-0" style="color: blue;">
                    <img src="@/assets/icons/vector.svg" alt="">
                    <span class="ms-2 text-">Remover</span>
                  </Button>
                </div>
                <div v-if="hasAvailableProductTypeMcafeeComputed">
                  <Button tag="Remover" @click="removeCart(cartItem.item.product, 'mcafee')" type="button" class="rounded edit-button-mcafee col py-0" style="color: blue;">
                    <img src="@/assets/icons/vector.svg" alt="">
                    <span class="ms-2 text-">Remover</span>
                  </Button>
                </div>
              </div>
              <div class="price-mcafee"> {{ cartItem.item.promotion?.description }}____  {{ currencyBRFormat(cartItem.price) }}</div>
              <div v-if="checkHasAdditionalPointsByCartItem(cartItem)">
                <div v-for="cartItem in getCartItems" :key="cartItem.item.productResidentialPointAdditionalType?.id">
                  <div v-if="getShowClaroFieldsComputed" class="price-mcafee">
                    {{ cartItem.item.productResidentialPointAdditionalAmount }} pontos adicionais 
                    {{ cartItem.item.productResidentialPointAdditionalType?.description }} ____  {{ currencyBRFormat(cartItem.itemPoint) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <br>
        <span v-if="getShowClaroFieldsComputed" class="total-price"> Valor Total: {{ currencyBRFormat(getCartTotalPricePoints) }}/mês</span>
        <span v-if="hasAvailableProductTypeMcafeeComputed" class="total-price"> Valor Total: {{ currencyBRFormat(getCartTotalPrice) }}/ano</span>
        <br>
        <br>
        <div v-if="hasAvailableProductTypeMcafeeComputed" class="container-fluid d-flex justify-content-center">
          <Button tag="Comprar Agora" type="button" class="btn btn-success col-10" @click="getStepCart">Comprar agora</Button>
        </div>
        <div class="col-12" v-if="getShowClaroFieldsComputed">
          <button pill variant="danger" type="submit" value="Submit"
            class="btn btn-success text-center col-12 col-sm-12 col-md-10 col-lg-12 loading-button"
            @click="toggleModal" :disabled="processing">
            <span v-if="!processing">Comprar agora</span>
            <span v-else>
              <div class="text-center">
                <strong class="text-sm">Aguardando Autentica.....</strong>
                <div class="spinner-border ml-auto" role="status" aria-hidden="true" style="width: 16px; height: 16px;"></div>
              </div>
            </span>
          </button>
        </div>
        <br>
        <br>
      </div>
    </div>
    <div class="offcanvas-backdrop fade show" />
  </div>
  <Login v-if="showModal" :close="close" />
  <AutenticaModal v-if="showModalAuthenticate" />
  <CustomModal v-show="showEmptyCartModal" :message="emptyCartMessage" />
  <CustomModal v-show="showSuccessModal" :message="successMessage" />
  <CustomModal v-show="showCustomModal" :message="emptyCartMessage" />
</template>

<script>
import MoneyService from "@/services/numbers/money";
import { mapActions, mapGetters, mapState } from "vuex";
import Login from "@/components/Login/Login.vue";
import Button from "@/components/Form/Button/Button.vue";
import AutenticaModal from "@/components/Modal/VerifyModal";
import CustomModal from "@/components/Modal/ModalCancel.vue";
import UrlConstants from "@/constants/url/url.constants.js";
import RoutesConstants from "@/constants/routes/routes.constants.js";

export default {
  name: "AsideCart",
  components: {
    Login,
    Button,
    AutenticaModal,
    CustomModal
  },
  data() {
    return {
      showModal: false,
      showCustomModal: false,
      showEmptyCartModal: false,
      showModalAuthenticate: false,
      processing: false,
      showSuccessModal: false,
      emptyCartMessage: '',
      successMessage: '',
    }
  },
  computed: {
    ...mapGetters({
      getCartDetailsComputed: 'cart/getCartDetails',
      hasAvailableProductTypeClaroComputed: 'saleFlow/product/hasAvailableProductTypeClaro',
      hasAvailableProductTypeMcafeeComputed: 'saleFlow/product/hasAvailableProductTypeMcafee',
      getShowClaroFieldsComputed: "address/getShowClaroFields",
      authenticatedCustomerComputed: 'auth/AuthorizationCustomer',
      isAuthenticatedCustomerComputed: 'auth/isAuthorizationCustomer',
      hasCheckoutProductThatRequireClaroAuthenticate: 'saleFlow/cart/hasCheckoutProductThatRequireClaroAuthenticate', 
      getProducts:"saleFlow/product/getProducts",
      getAuthenticatorRequired: "cart/getAuthenticatorRequired",
      getShowModal: 'saleFlow/operators/claro/authenticate/showModalAuthenticate',
      getCheckoutProductsItemsComputed: "saleFlow/cart/getCheckoutProductsItems",
      isCartEmpty: 'cart/isCartEmpty',
      getPartnerComputed: "getPartner",
    }),
    ...mapState({
      isShowingCart: (state) => state.cart.isShowingCart
    }),
    currencyBRFormat() {
      return (value) => MoneyService.currencyBRFormat(value || 0);
    },
    getCartItems() {
      return this.getCartDetailsComputed?.items || []
    },
    getCartTotalPrice() {
      return this.getCartDetailsComputed?.total || 0
    },
    getCartTotalPoints() {
      return this.getCartDetailsComputed?.totalPoints || 0
    },
    getCartTotalPricePoints(){
      return this.getCartDetailsComputed?.totalPrice || 0
    },
    getButtonMarginLeft() {
      return this.hasAvailableProductTypeMcafeeComputed ? '5%' : '-67%';
    },
  },
  methods: {
    ...mapActions({
      closeCartAction: 'cart/closeCart',
      removeCartItemAction: 'cart/removeProductCart',
      openModalClaroAuthenticateAction:"saleFlow/operators/claro/authenticate/openModal",
      authenticatorRequired:'cart/authenticatorRequired',
      openModal:'saleFlow/operators/claro/authenticate',
      generateClaroAuthenticateLinkAction: 'saleFlow/operators/claro/authenticate/generateLink',
      removeProductAction: 'saleFlow/cart/removeProduct',
    }),
    openModal(){
      this.getShowModal;
    },
    closeCart() {
      this.closeCartAction();
    },
    showEmptyCart() {
        this.showEmptyCartModal = true;
        this.emptyCartMessage = "Seu produto foi removido do carrinho, seu carrinho ficou vazio. Você pode retornar e escolher um novo.";
    },
    showSuccess() {
      this.showSuccessModal = true;
      this.successMessage = "Seu produto foi removido com sucesso.";
    },
    removeCart(product, productType) {
      this.removeCartItemAction(product, productType);
      const checkoutProductId = this.getCheckoutProductsItemsComputed?.id;
      this.removeProductAction(checkoutProductId);
      this.closeCartAction();

      this.isCartEmpty ? this.showEmptyCart() : this.showSuccess()

      const partnerUrl = this.getPartnerComputed;

      if (partnerUrl === UrlConstants.TRADE_APP_ONE_URL) {
        this.navigateToRoute(RoutesConstants.TRADE_APP_ONE_ROUTE)
      }
    },
      navigateToRoute(route) {
    return this.$router.push({ path: route });
  },
    getStepCart() {
      if (!this.getCartItems.length) {
        this.emptyCartMessage = "Por favor, adicione um produto ao carrinho para continuar.";
        this.showCustomModal = true;
        return;
      }
      if (this.hasAvailableProductTypeClaroComputed) {
        this.closeCartAction();
        return this.$router.push({ name: 'ProductStep' });
      }
      this.closeCartAction()
        .then(() => {
          this.$router.push({ name: 'CustomerStep' });
        });
    },
    async toggleModal() {
      if (this.processing || !this.getCartItems.length) {
        this.emptyCartMessage = "Por favor, adicione um produto ao carrinho para continuar.";
        this.showCustomModal = true;
        return;
      }
      this.showModal = !this.showModal;
      if (this.isAuthenticatedCustomerComputed) {
        this.showModal = false;
        this.processing = true;
        if (this.getAuthenticatorRequired) {
          try {
            await this.generateClaroAuthenticateLinkAction();
            this.showModalAuthenticate = true;
            this.getStepCart();
          } catch (error) {
            this.emptyCartMessage = 'Erro ao gerar o link de autenticação, reinicie o processo.';
            this.showCustomModal = true;
          } finally {
            this.processing = false;
          }
        } else {
          this.getStepCart();
          this.processing = false;
        }
      }
    },
    checkHasAdditionalPointsByCartItem(cartItem) {
      return cartItem?.item?.productResidentialPointAdditionalAmount > 0;
    },
    close() {
      this.showModal = false;
    },
    async resetModals() {
    try {

      this.showEmptyCartModal = false;
      this.showSuccessModal = false;
      this.showCustomModal = false;
      this.showModalAuthenticate = false;
      this.showModal = false;

    } catch (error) {
      console.error('Erro ao resetar os modais:', error);
    }
    },
  async resetModalsAction() {
    await this.resetModals();
  },
  }
};
</script>

<style scoped>
.edit-button-mcafee {
  background: rgb(214, 210, 210);
  margin-left: 60%;
  margin-top: -36%;
  font-size: 12px;
  margin-bottom: -16px;
}
.edit-button-claro {
  background: rgb(214, 210, 210);
  margin-left: 60%;
  margin-top: 13%;
  font-size: 12px;
  margin-bottom: -16px;
}
.text-mcafee {
  font-size: 13px;
  font-weight: bold;
}
.price-mcafee {
  margin-left: 0%;
  margin-top: 1%;
  font-size: 11px;
  font-weight: bold;
}
.total-price {
  margin-left: 21%;
}
</style>
