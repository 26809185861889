<template>
  <div>
    <CookiesPolicy v-if="!shouldShowCookiesPolicy"/>
    <AsideCart/>
    <MyOrders/>
    <RegionalizationModal/>
    <router-view/>
  </div>
</template>

<script>
import CookiesPolicy from "@/components/Policy/CookiesPolicy"
import AsideCart from "@/components/Cart/AsideCart"
import RegionalizationModal from "@/components/Regionalization/RegionalizationModal"
import MyOrders from "@/components/Cart/MyOrders.vue"

export default {
  name: "Container",
  components: {
    CookiesPolicy,
    AsideCart,
    MyOrders,
    RegionalizationModal,
  },
   computed: {
    shouldShowCookiesPolicy() {
      return this.$route.name === 'HomePageDarkWeb' ||  this.$route.name === 'HomePageStatic'
    }
  }
}
</script>
